<template>
  <div>
    <el-input
      placeholder="请输入内容"
      suffix-icon="el-icon-search"
      v-model="findKey"
      @change="search"
    >
    </el-input>
    <div></div>
    <el-table :data="searchData" border style="width: 100%">
      <el-table-column prop="cname" label="患者姓名" align="center" width="150">
      </el-table-column>
      <el-table-column prop="etId" label="评估编号" align="center" width="150">
      </el-table-column>
      <el-table-column
        prop="etName"
        label="评估模块"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="dateTime"
        label="评估时间"
        align="center"
        width="200"
      ></el-table-column>
      <el-table-column label="评估分数" align="center" width="150">
        <template slot-scope="scope">{{ scope.row.score }}</template>
      </el-table-column>
      <el-table-column
        label="分数结果"
        prop="solution"
        align="center"
        width="150"
      >
      </el-table-column>

      <el-table-column label="身份证号" align="center" width="220"
        ><template slot-scope="scope">{{
          scope.row.cid | hideMiddle
        }}</template>
      </el-table-column>
      <el-table-column
        prop="review"
        label="治疗建议"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-view"
            @click="$router.push(`/OneReport/${scope.row.cid}/${scope.row.id}`)"
            >查看</el-button
          >
          <el-button
            @click="delBnt(scope.row.id)"
            size="mini"
            type="danger"
            icon="el-icon-circle-close"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹出框 -->
    <el-dialog
      title="修改报告信息"
      :visible.sync="dialogVisible2"
      width="30%"
      :before-close="handleClose"
    >
      <span>
        <el-form ref="form" :model="update" label-width="80px" size="mini">
          <h2>光照疗效指标测验报告</h2>
          <div>重庆市精神卫生中心</div>
          <table>
            <tbody>
              <td>姓名：{{ update.cname }}</td>
              <td>患者ID：{{ update.cid }}</td>
            </tbody>
          </table>
        </el-form>
      </span>
      <el-button @click="noUpdateRepBtn">取 消</el-button>
      <el-button type="primary" @click="updateRepBtn">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import api from "../http/api";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("patient");
export default {
  data() {
    return {
      n_solution: "",
      curretn: "所有报告",
      findKey: "",
      searchData: [],
      dialogVisible2: false,
      // handleClose: false,
      // 存放修改报告数据
      update: {},
      // _id: "",
      // Cname: "",
      // ETID: "",
      // CID: "",
      // ETName: "",
      // Data: "",
      // Score: "",
      // Solution: "",
      // Review: "",
      // Section: "",
      // Doctor: "",
    };
  },

  created() {
    this.lookRport();
  },
  mounted() {
    // console.log("查询数据", this.searchData);
  },
  computed: {
    ...mapState(["rport"]),
  },
  filters: {
    hideMiddle(val) {
      return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
    },
  },
  methods: {
    ...mapActions(["lookRport", "updateReport", "delRport"]),
    // 通过关键字查询患者
    async search() {
      const data = await api.patient.lookRport(this.findKey);
      if (data.status == 200) {
        this.searchData = data.data.data;
        // console.log("查询数据", this.searchData);
      }
    },
    getOneReport() {
      this.$router.push("/OneReport");
    },
    //   点击修改的弹出框方法
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    // 确定修改报告
    updateRepBtn() {
      this.updateReport();
      Message.success("修改成功");
      this.dialogVisible2 = false;
    },
    // 取消修改报告
    noUpdateRepBtn() {
      this.dialogVisible2 = false;
      Message.info("已取消修改");
    },
    // 删除患者
    delBnt(id) {
      this.search();
      // 删除提示框
      this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功！",
          });

          this.delRport(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.el-input {
  width: 200px;
}
</style>
